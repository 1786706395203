import Accordion from '../../components/ui/Accordion';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { moreFaqData, moreFaqDataBn } from '../../utils';
import { getLocaleString } from '../../utils/locales';

const MoreFaq = () => {
  const locale = getData(LANGUAGE);
  const data = locale === 'en' ? moreFaqData : moreFaqDataBn;
  return (
    <div>
      <div className="bg-white">
        <div className="flex items-center justify-center py-9 md:py-12 lg:py-16 ">
          <div>
            
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl text-center">
              {getLocaleString(`frequently_asked_questions`, locale)}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full pb-16 lg:px-80 px-9 md:px-24">
        <div>
          {data.map((faq: any, index: any) => (
            <div className="">
            <Accordion key={index} title={faq.title} body={faq.body} index={index} locale={locale} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreFaq;
