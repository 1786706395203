// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import Box from '@mui/material/Box';
// import FormControl from '@mui/material/FormControl';
// import MenuItem from '@mui/material/MenuItem';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { LANGUAGE } from '../../constants/localStorage';
import useLanguageSwitch from '../../hooks/useLanguageSwitch';
import { getData } from '../../services/storage';


// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
    
//     select: {
//       background: 'linear-gradient(to right bottom, #e9be5a, #c6952c)',
//       outline:'none',
//       //  padding: '12px',
//        borderRadius: '8px', 
//        fontWeight: 600
//     },
//   }),
// );

export default function LanguageSelect() {
  const { handleLanguageToggle } = useLanguageSwitch();
  const [language, setLanguage] = React.useState(getData(LANGUAGE));
// const classes=useStyles();
  // const handleChange = (event: SelectChangeEvent) => {
  //   setLanguage(event.target.value as string);
  //   handleLanguageToggle(event.target.value)
  // };

  return (

    //    <Box sx={{ minWidth: 120 }}>
    //   <FormControl sx={{ minWidth: 120 }}>
        
    //     <Select
    //     variant="standard"
    //       labelId="language-select-label"
    //       id="language-select"
    //       value={language ? language : 'en'}
    //       label="language"
    //       onChange={handleChange}
    //        displayEmpty
    //       inputProps={{ 'aria-label': 'Without label' }}
    //       className={classes.select}
    //       disableUnderline 
    //       style={{padding: language==='bn'?'12px':'12.5px'}}
    //     >
    //       <MenuItem value={'en'}>English</MenuItem>
    //       <MenuItem value={'bn'}>বাংলা</MenuItem>
    //     </Select>
    //   </FormControl>
    // </Box>
    <div className='flex space-x-1 hover:cursor-pointer '>
      <p onClick={()=>{setLanguage('en');
      handleLanguageToggle('en')}}  
      className={`${language==='en'?'font-semibold text-sm text-transparent bg-clip-text bg-gradient-to-r from-[#e9be5a] to-[#c6952c]':'no-underline text-sm text-white'}`}
      >English </p>
      <p className='text-white text-sm'>|</p>
      <p onClick={()=>{setLanguage('bn');
      handleLanguageToggle('bn')}} 
      className={`${language==='bn'?'font-semibold text-sm text-transparent bg-clip-text bg-gradient-to-r from-[#e9be5a] to-[#c6952c]':'no-underline text-sm text-white'}`}
      >বাংলা</p>
    </div>
   
  );
}