import { useEffect, useRef, useState } from 'react';
import ImageContainer from '../../components/ui/ImageContainer';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
import SliderPage from './SliderPage';
import './styles.css';

const HowItWorks = () => {
  const locale = getData(LANGUAGE);
  const [image, setImage] = useState(getLocaleString('how_it_works_img', locale));

  useEffect(() => {
    setImage(getLocaleString('how_it_works_img', locale))
  }, [locale])

  const [headerColor, setHeaderColor] = useState('#e9be5a');
  const [headerColor2, setHeaderColor2] = useState('#e9be5a');
  const [headerColor3, setHeaderColor3] = useState('#e9be5a');
  const [headerColor4, setHeaderColor4] = useState('#e9be5a');
  const [headerColor5, setHeaderColor5] = useState('#e9be5a');
  const [headerColor6, setHeaderColor6] = useState('#e9be5a');

  const [bgColor1, setBgColor1] = useState('#FFFFFF');
  const [bgColor2, setBgColor2] = useState('#FFFFFF');
  const [bgColor3, setBgColor3] = useState('#FFFFFF');
  const [bgColor4, setBgColor4] = useState('#FFFFFF');
  const [bgColor5, setBgColor5] = useState('#FFFFFF');
  // const [bgColor6, setBgColor6] = useState('#FFFFFF');

  const listenScrollEvent = () => {
    window.scrollY > 4000
      ? image
      : image;
    window.scrollY > 4500 ? setHeaderColor('#e9be5a') : setHeaderColor('#e9be5a');
    window.scrollY > 4600 ? setHeaderColor2('#e9be5a') : setHeaderColor2('#e9be5a');
    window.scrollY > 4700 ? setHeaderColor3('#e9be5a') : setHeaderColor3('#e9be5a');
    window.scrollY > 5000 ? setHeaderColor4('#e9be5a') : setHeaderColor4('#e9be5a');
    window.scrollY > 5100 ? setHeaderColor5('#e9be5a') : setHeaderColor5('#e9be5a');
    window.scrollY > 5300 ? setHeaderColor6('#e9be5a') : setHeaderColor6('#e9be5a');

    window.scrollY > 4500 ? setBgColor1('#ffff') : setBgColor1('');
    window.scrollY > 4600 ? setBgColor2('#ffff') : setBgColor2('');
    window.scrollY > 5000 ? setBgColor3('#ffff') : setBgColor3('');
    window.scrollY > 5200 ? setBgColor4('#ffff') : setBgColor4('');
    window.scrollY > 5300 ? setBgColor5('#ffff') : setBgColor5('');
    // window.scrollY > 5600 ? setBgColor6('#e9be5a') : setBgColor6('');
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
  });

  const [red, setRed] = useState(0);
  const [, setScrollPosition] = useState(0);
  const [, setWindowSize] = useState(getWindowSize());

  const [red1, setRed1] = useState(0);
  const [, setScrollPosition1] = useState(0);
  const [, setWindowSize1] = useState(getWindowSize1());

  const [red2, setRed2] = useState(0);
  const [, setScrollPosition2] = useState(0);
  const [, setWindowSize2] = useState(getWindowSize2());

  const [red3, setRed3] = useState(0);
  const [, setScrollPosition3] = useState(0);
  const [, setWindowSize3] = useState(getWindowSize3());

  const [red4, setRed4] = useState(0);
  const [, setScrollPosition4] = useState(0);
  const [, setWindowSize4] = useState(getWindowSize4());

  const [red5, setRed5] = useState(0);
  const [, setScrollPosition5] = useState(0);
  const [, setWindowSize5] = useState(getWindowSize5());

  const [red6, setRed6] = useState(0);
  const [, setScrollPosition6] = useState(0);
  const [, setWindowSize6] = useState(getWindowSize6());

  const [red7, setRed7] = useState(0);
  const [, setScrollPosition7] = useState(0);
  const [, setWindowSize7] = useState(getWindowSize7());

  const [red8, setRed8] = useState(0);
  const [, setScrollPosition8] = useState(0);
  const [, setWindowSize8] = useState(getWindowSize8());

  const [red9, setRed9] = useState(0);
  const [, setScrollPosition9] = useState(0);
  const [, setWindowSize9] = useState(getWindowSize9());

  const [red10, setRed10] = useState(0);
  const [, setScrollPosition10] = useState(0);
  const [, setWindowSize10] = useState(getWindowSize10());

  // const [red11, setRed11] = useState(0);
  // const [, setScrollPosition11] = useState(0);
  // const [windowSize11, setWindowSize11] = useState(getWindowSize11());

  const myRef = useRef<any>();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setRed((position - 3000) / 3000);
    setScrollPosition(position);

    setRed1((position - 100) / 100);
    setScrollPosition1(position);

    setRed2((position - 100) / 100);
    setScrollPosition2(position);

    setRed3((position - 100) / 100);
    setScrollPosition3(position);

    setRed4((position - 100) / 100);
    setScrollPosition4(position);

    setRed5((position - 100) / 100);
    setScrollPosition5(position);

    setRed6((position - 100) / 100);
    setScrollPosition6(position);

    setRed7((position - 100) / 100);
    setScrollPosition7(position);

    setRed8((position - 100) / 100);
    setScrollPosition8(position);

    setRed9((position - 100) / 100);
    setScrollPosition9(position);

    setRed10((position - 100) / 100);
    setScrollPosition10(position);

    // setRed11((position - 100) / 100);
    // setScrollPosition11(position);

    // console.log(position);
    // console.log('windowSize', windowSize);
    // console.log('windowSize1', windowSize1);
    // console.log('windowSize2', windowSize2);
    // console.log('windowSize3', windowSize3);
    // console.log('windowSize1', windowSize4);
    // console.log('windowSize2', windowSize5);
    // console.log('windowSize3', windowSize6);
    // console.log('windowSize7', windowSize7);
    // console.log('windowSize8', windowSize8);
    // console.log('windowSize9', windowSize9);
    // console.log('windowSize10', windowSize10);
    // console.log('windowSize11', windowSize11);

    // console.log(
    //   position,
    //   myRef.current.offsetTop,
    //   (929 + position - myRef.current.offsetTop - 500) / 2,
    // );
    // console.log(
    //   position,
    //   myRef.current.offsetTop,
    //   (800 + position - myRef.current.offsetTop - 500) / 2,
    // );
    var redPercent = (1600 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent <= 100) setRed(redPercent);
    else if (redPercent > 100) setRed(100);

    var redPercent1 = (1500 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent1 <= 100) setRed1(redPercent1);
    else if (redPercent1 > 100) setRed1(100);

    var redPercent2 = (1400 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent2 <= 100) setRed2(redPercent2);
    else if (redPercent2 > 100) setRed2(100);

    var redPercent3 = (1200 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent3 <= 100) setRed3(redPercent3);
    else if (redPercent3 > 100) setRed3(100);

    var redPercent4 = (1100 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent4 <= 100) setRed4(redPercent4);
    else if (redPercent4 > 100) setRed4(100);

    var redPercent5 = (1000 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent5 <= 100) setRed5(redPercent5);
    else if (redPercent5 > 100) setRed5(100);

    var redPercent6 = (900 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent6 <= 100) setRed6(redPercent6);
    else if (redPercent6 > 100) setRed6(100);

    var redPercent7 = (800 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent7 <= 100) setRed7(redPercent7);
    else if (redPercent7 > 100) setRed7(100);

    var redPercent8 = (700 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent8 <= 100) setRed8(redPercent8);
    else if (redPercent8 > 100) setRed8(100);

    var redPercent9 = (600 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent9 <= 100) setRed9(redPercent9);
    else if (redPercent9 > 100) setRed9(100);

    var redPercent10 = (580 + position - myRef.current.offsetTop - 500) / 2;
    if (redPercent10 <= 100) setRed10(redPercent10);
    else if (redPercent10 > 100) setRed10(100);

    // var redPercent11 = (400 + position - myRef.current.offsetTop - 500) / 2;
    // if (redPercent11 <= 100) setRed11(redPercent11);
    // else if (redPercent11 > 100) setRed11(100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      setWindowSize1(getWindowSize1());
      setWindowSize2(getWindowSize2());
      setWindowSize3(getWindowSize3());
      setWindowSize4(getWindowSize4());
      setWindowSize5(getWindowSize5());
      setWindowSize6(getWindowSize6());
      setWindowSize7(getWindowSize7());
      setWindowSize8(getWindowSize8());
      setWindowSize9(getWindowSize9());
      setWindowSize10(getWindowSize10());
      // setWindowSize11(getWindowSize11());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div id="how-it-works" className="w-full">
      <div id="container" className="hidden lg:inline">
        <span className="items-center justify-start hidden md:flex md:justify-center">
          <img
          
            src={image}
            style={{ transition: `${image}  20s ease` }}
            // className="transition duration-700 ease-in-out"
          />
          {/* <ImageContainer src={image} alt="How it works" className=""  /> */}
        </span>
        <div
          ref={myRef}
          style={{
            color: '#e9be5a',
            width: '2px',
            height: '80px',
            background: `linear-gradient(180deg, #e9be5a ${red}%, #e9be5a ${0}%)`,
            transition: 'all 1s ease',
            margin: 'auto',
          }}
        ></div>
        <div className="flex items-center justify-center ml-10">
          <div className="">
            <div
              ref={myRef}
              style={{
                color: '#e9be5a',
                background: `linear-gradient(-90deg, #e9be5a ${red1}%, #e9be5a ${0}%)`,
                transition: 'all 1s ease',
                width: '170px',
                height: '2px',
                marginLeft: '140px',
              }}
            ></div>
            <div
              style={{
                color: '#e9be5a',
                width: '2px',
                height: '60px',
                background: `linear-gradient(180deg, #e9be5a ${red2}%, #e9be5a ${0}%)`,
                transition: 'all 1s ease',
                marginLeft: '140px',
              }}
            >
              <span
                style={{
                  backgroundColor: '#e9be5a',
                  marginTop: ' 46px',
                  marginLeft: '-8px',
                  display: 'inline-block',
                  borderRadius: '50%',
                  width: ' 16px',
                  height: '16px',
                  background: `linear-gradient(180deg, #e9be5a ${red2}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                }}
              ></span>
            </div>
            <div className="flex -mt-1">
              <div
                className="h-40  w-72 rounded-2xl border-2 border-[#e9be5a]"
                style={{
                  borderColor: headerColor2,
                  background: `${bgColor1} `,
                  transition: 'background 2s ease',
                  // background: `linear-gradient(180deg, #e9be5a ${bgColor1}%, #e9be5a ${0}%)`,
                }}
              >
                <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('step_1', locale)}</p>
                <p className="text-[#010103]/54 text-center text-base font-normal px-1">
                  {getLocaleString('step_1_description', locale)}
                </p>
              </div>
              <div
                ref={myRef}
                style={{
                  color: '#e9be5a',
                  background: `linear-gradient(90deg, #e9be5a ${red3}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  width: '50px',
                  height: '2px',
                  marginLeft: '0px',
                  marginTop: '82px',
                }}
              ></div>
              <span
                style={{
                  backgroundColor: '#e9be5a',
                  marginTop: '76px',
                  marginRight: '-8px',
                  display: 'inline-block',
                  borderRadius: '50%',
                  width: ' 16px',
                  height: '16px',
                  background: `linear-gradient(180deg, #e9be5a ${red3}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                }}
              ></span>
              <div
                className="h-40 w-72 rounded-2xl border-2 border-[#e9be5a]"
                style={{
                  borderColor: headerColor3,
                  background: bgColor2,
                  transition: 'background 2s ease',
                }}
              >
                <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('step_2', locale)}</p>
                <p className="text-[#010103]/54 text-center text-base font-normal px-1">
                  {getLocaleString('step_2_description', locale)}
                </p>
              </div>
            </div>
            <div className="ml-40">
              <div
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '110px',
                  background: `linear-gradient(180deg, #e9be5a ${red4}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '350px',
                }}
              ></div>
              <div
                ref={myRef}
                style={{
                  color: '#e9be5a',
                  background: `linear-gradient(-90deg, #e9be5a ${red5}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  width: '182px',
                  height: '2px',
                  marginLeft: '170px',
                }}
              ></div>
              <div
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '60px',
                  background: `linear-gradient(180deg, #e9be5a ${red6}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '170px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: ' 46px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red6}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
            </div>

            <div className="ml-48 -mt-1">
              <div className="">
                <div
                  className="h-40 border-2 w-72 rounded-2xl"
                  style={{
                    borderColor: headerColor4,
                    background: bgColor3,
                    transition: 'background 3s ease',
                  }}
                >
                  <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('step_3', locale)}</p>
                  <p className="text-[#010103]/54 text-center text-base font-normal">
                    {getLocaleString('step_3_description', locale)}
                  </p>
                </div>
              </div>
              <div
                ref={myRef}
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '110px',
                  background: `linear-gradient(180deg, #e9be5a ${red7}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '140px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: '96px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
            </div>
            <div className="flex items-center justify-center -mt-16">
              <div>
                <div
                  style={{
                    marginRight: '0px',
                    width: '660px',
                    height: '2px',
                    color: ' #e9be5a',
                    background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></div>
              </div>
            </div>
            <div>
              <div className="flex justify-between">
                <div
                  style={{
                    color: '#e9be5a',
                    width: '2px',
                    height: '60px',
                    background: `linear-gradient(180deg, #e9be5a ${red9}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                    marginLeft: '0px',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: '#e9be5a',
                      marginTop: ' 46px',
                      marginLeft: '-8px',
                      display: 'inline-block',
                      borderRadius: '50%',
                      width: ' 16px',
                      height: '16px',
                      background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                      transition: 'all 1s ease',
                    }}
                  ></span>
                </div>
                <div
                  style={{
                    color: '#e9be5a',
                    width: '2px',
                    height: '60px',
                    background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                    marginRight: '0px',
                  }}
                > 
                  <span
                    style={{
                      backgroundColor: '#e9be5a',
                      marginTop: ' 46px',
                      marginLeft: '-8px',
                      display: 'inline-block',
                      borderRadius: '50%',
                      width: ' 16px',
                      height: '16px',
                      background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                      transition: 'all 1s ease',
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div>
            <div
              className="ml-10 -mt-1 border-2 h-80 w-72 rounded-2xl"
              style={{
                borderColor: headerColor5,
                background: bgColor4,
                transition: 'background 3s ease',
              }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
              </div>
              <p className="my-4 mt-4 text-xl font-semibold text-center ">{getLocaleString('sell_gold', locale)}</p>
              <p className="text-[#010103]/54 text-center text-base font-normal p-2">
                {getLocaleString('sell_gold_home_description', locale)}
              </p>
            </div>
            <div>
              <div
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '105px',
                  background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '180px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: '90px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
              <div
                className="md:ml-10 lg:ml-10 xl:ml-30 -mt-1 border-2 h-80 w-72 rounded-2xl"
                style={{
                  borderColor: headerColor6,
                  background: bgColor5,
                  transition: 'background 3s ease',
                }}
              >
                <div className="flex items-center justify-center mt-8">
                  <ImageContainer
                    src="/image/howItWorks/withdraw.png"
                    alt="withdraw.png"
                    className=""
                  />
                </div>
                <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('withdraw_cash', locale)}</p>
                <p className="text-[#010103]/54 px-4 text-center text-base font-normal p2">
                  {getLocaleString('withdraw_cash_home_description', locale)}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              // id="no2"
              className="ml-10 -mt-1 border-2 h-80 w-72 rounded-2xl"
              style={{
                borderColor: headerColor5,
                background: bgColor4,
                transition: 'background 3s ease',
                // marginLeft: '10px',
                // float: 'left',
              }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
              </div>
              <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('collect_gold', locale)}</p>
              <p className="text-[#010103]/54 text-center text-base font-normal p-2">
                {getLocaleString('collect_gold_home_description', locale)}
              </p>
            </div>
            
              <div>
                <div className='flex items-center'>
                  <div
                    style={{
                      color: '#e9be5a',
                      width: '2px',
                      height: '105px',
                      background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                      transition: 'all 1s ease',
                      marginLeft: '180px',
                    }}
                  > 
                    <span
                      style={{
                        backgroundColor: '#e9be5a',
                        marginTop: ' 90px',
                        marginLeft: '-8px',
                        display: 'inline-block',
                        borderRadius: '50%',
                        width: ' 16px',
                        height: '16px',
                        background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                        transition: 'all 1s ease',
                      }}
                    ></span>
                  </div>
                  <div
                    style={{
                      marginLeft:'180px',
                      position:'absolute',
                      width: '330px',
                      height: '2px',
                      color: ' #e9be5a',
                      background: `linear-gradient(180deg, #e9be5a ${red8}%, #e9be5a ${0}%)`,
                      transition: 'all 1s ease',
                    }}
                  ></div>
                </div>
                
                  <div
                    className="ml-10 -mt-1 border-2 h-80 w-72 rounded-2xl"
                    style={{
                      borderColor: headerColor6,
                      background: bgColor5,
                      transition: 'background 3s ease',
                    }}
                  >
                    <div className="flex items-center justify-center mt-8">
                      <ImageContainer src="/image/howItWorks/car.svg" alt="car.svg" className="" />
                    </div>
                    <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('home_delivery', locale)}</p>
                    <p className="text-[#010103]/54 text-center text-base font-normal px-5">
                      {getLocaleString('home_delivery_description', locale)}
                    </p>
                  </div>
                
              </div>
              
            

          </div>
          <div>
            <div
              className="ml-10 -mt-1 border-2 h-80 w-72 rounded-2xl"
              style={{
                borderColor: headerColor5,
                background: bgColor4,
                transition: 'background 3s ease',
                // marginRight: '100px',
                // float: 'right',
              }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/gift.svg" alt="gift.svg" className="" />
              </div>
              <p className="my-5 mt-5 text-xl font-semibold text-center">{getLocaleString('gift_gold', locale)}</p>
              <p className="text-[#010103]/54 text-center text-base font-normal p-2">
                {getLocaleString('gift_gold_home_description', locale)}
              </p>
            </div>
            <div>
              
              <div
                style={{
                  color: '#e9be5a',
                  marginTop:'52px',
                  width: '2px',
                  height: '50px',
                  background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '180px',
                }}
              > 
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: ' 38px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
              
                <div
                  className="ml-10 border-2 h-80 w-72 rounded-2xl"
                  style={{
                    borderColor: headerColor6,
                    background: bgColor5,
                    transition: 'background 3s ease',
                  }}
                >
                  <div className="flex items-center justify-center mt-8">
                    <ImageContainer src="/image/howItWorks/pickup.png" alt="pickup.png" className="" />
                  </div>
                  <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('pickup_delivery', locale)}</p>
                  <p className="text-[#010103]/54 text-center text-base font-normal px-5">
                    {getLocaleString('pickup_delivery_description', locale)}
                  </p>
                </div>
              
              </div>
          </div>
        </div>
        <div className="ml-20 md:ml-10 lg:ml-20">
          <div className="flex ml-48">
            {/* <div>
              <div
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '60px',
                  background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '180px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: ' 46px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
              <div
                className="md:ml-10 lg:ml-10 xl:ml-30 -mt-1 border-2 h-80 w-72 rounded-2xl"
                style={{
                  borderColor: headerColor6,
                  background: bgColor5,
                  transition: 'background 3s ease',
                }}
              >
                <div className="flex items-center justify-center mt-8">
                  <ImageContainer
                    src="/image/howItWorks/withdraw.png"
                    alt="withdraw.png"
                    className=""
                  />
                </div>
                <p className="my-4 mt-4 text-xl font-semibold text-center">Withdraw Cash</p>
                <p className="text-[#010103]/54 px-4 text-center text-base font-normal">
                  Withdraw your gold Savings as cash in your preferred MFS or Bank Account
                </p>
              </div>
            </div> */}
            {/* <div>
              <div
                style={{
                  color: '#e9be5a',
                  width: '2px',
                  height: '60px',
                  background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                  transition: 'all 1s ease',
                  marginLeft: '180px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#e9be5a',
                    marginTop: ' 46px',
                    marginLeft: '-8px',
                    display: 'inline-block',
                    borderRadius: '50%',
                    width: ' 16px',
                    height: '16px',
                    background: `linear-gradient(180deg, #e9be5a ${red10}%, #e9be5a ${0}%)`,
                    transition: 'all 1s ease',
                  }}
                ></span>
              </div>
              <div
                className="ml-10 -mt-1 border-2 h-80 w-72 rounded-2xl"
                style={{
                  borderColor: headerColor6,
                  background: bgColor5,
                  transition: 'background 3s ease',
                }}
              >
                <div className="flex items-center justify-center mt-8">
                  <ImageContainer src="/image/howItWorks/car.svg" alt="car.svg" className="" />
                </div>
                <p className="my-4 mt-4 text-xl font-semibold text-center">Home Delivery</p>
                <p className="text-[#010103]/54 text-center text-base font-normal">
                  Get your stored gold, from <br /> your vault, delivered right at <br /> your
                  doorstep in forms of <br /> gold coins or gold bars
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <ImageContainer src={image} alt="How it works" className="" />
        <div style={{ background: headerColor4 }} id="line16">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div className="h-40 border w-68 rounded-2xl " style={{ borderColor: headerColor }}>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('step_1', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 p-2">
              {getLocaleString('step_1_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div
            className="w-68 h-40 rounded-2xl border border-[#BDBDBD]"
            style={{ borderColor: headerColor2 }}
          >
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('buy_gold', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('buy_gold_home_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div className="h-40 border w-68 rounded-2xl" style={{ borderColor: headerColor3 }}>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('store_gold', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50">
              {getLocaleString('store_gold_home_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div className="border w-68 h-80 rounded-2xl" style={{ borderColor: headerColor4 }}>
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
            </div>
            <p className="mb-4 text-xl font-semibold text-center ">{getLocaleString('sell_gold', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('sell_gold_home_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div className="border w-68 h-80 rounded-2xl" style={{ borderColor: headerColor5 }}>
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/withdraw.png" alt="withdraw.png" className="" />
            </div>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('withdraw_cash', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('withdraw_cash_home_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div
            // id="no2"
            className="border w-68 h-80 rounded-2xl"
            style={{ borderColor: headerColor4 }}
          >
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
            </div>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('collect_gold', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('collect_gold_home_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div
            // id="no4"
            className="border w-68 h-80 rounded-2xl"
            style={{ borderColor: headerColor5 }}
          >
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/car.svg" alt="car.svg" className="" />
            </div>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('home_delivery', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('home_delivery_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div
            // id="no4"
            className="border w-68 h-80 rounded-2xl"
            style={{ borderColor: headerColor5 }}
          >
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/pickup.png" alt="pickup.png" className="" />
            </div>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('pickup_delivery', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50">
              {getLocaleString('pickup_delivery_description', locale)}
            </p>
          </div>
        </div>
        <div style={{ background: headerColor4 }} id="line17">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div className="mt-24 px-9">
          <div
            // id="no3"
            className="border w-68 h-80 rounded-2xl"
            style={{ borderColor: headerColor4 }}
          >
            <div className="flex items-center justify-center mt-8">
              <ImageContainer src="/image/howItWorks/gift.svg" alt="gift.svg" className="" />
            </div>
            <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('gift_gold', locale)}</p>
            <p className="text-center text-base font-normal text-[#010103]/50 px-2">
              {getLocaleString('gift_gold_home_description', locale)}
            </p>
          </div>
        </div>
      </div>

      <div id="container" className="hidden md:inline lg:hidden">
        <span
          className="items-center justify-start hidden md:flex md:justify-center"
          // onClick={() => setImage('image/howItWorks/howItWorks2.png')}
        >
          <ImageContainer src={image} alt="How it works" className="" />
        </span>
        <div style={{ background: headerColor }} id="line1"></div>
        <div className="ml-0 lg:ml-96">
          <div className="ml-16 lg:ml-72">
            <div style={{ background: headerColor }} id="line0"></div>

            <div style={{ background: headerColor }} id="line3">
              <span style={{ background: headerColor }} className="circle1"></span>
            </div>
            <div className="flex mt-10">
              <div className="h-40 border w-72 rounded-2xl " style={{ borderColor: headerColor }}>
                <p className="my-4 mt-4 text-xl font-semibold text-center"> {getLocaleString('step_1', locale)}</p>
                <p className="text-center text-base font-normal text-[#010103]/50">
                {getLocaleString('step_1_description', locale)}
                </p>
              </div>
              <div style={{ background: headerColor2 }} id="line4"></div>
              <span style={{ background: headerColor2 }} className="circle2"></span>
              <div
                className="h-40 w-72 rounded-2xl border border-[#BDBDBD]"
                style={{ borderColor: headerColor2 }}
              >
                <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('buy_gold', locale)}</p>
                <p className="text-center text-base font-normal text-[#010103]/50">
                  {getLocaleString('buy_gold_home_description', locale)}
                </p>
              </div>
            </div>
            <div className="ml-28">
              <div style={{ background: headerColor3 }} id="line5"></div>
              <div style={{ background: headerColor3 }} id="line6"></div>
              <div style={{ background: headerColor3 }} id="line7">
                <span style={{ background: headerColor3 }} className="circle3"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:inline lg:hidden">
        <div
          className="h-40 mt-10 border ml-60 w-72 rounded-2xl"
          style={{ borderColor: headerColor3 }}
        >
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('store_gold', locale)}</p>
          <p className="px-4 text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('store_gold_home_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          // id="no1"
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor4 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
          </div>
          <p className="mb-4 text-xl font-semibold text-center ">{getLocaleString('sell_gold', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('sell_gold_home_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor5 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/withdraw.png" alt="withdraw.png" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('withdraw_cash', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('withdraw_cash_home_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          // id="no2"
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor4 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('collect_gold', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
          {getLocaleString('collect_gold_home_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          // id="no4"
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor5 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/car.svg" alt="car.svg" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('home_delivery', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('home_delivery_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          // id="no4"
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor5 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/pickup.png" alt="pickup.png" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('pickup_delivery', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('pickup_delivery_description', locale)}
          </p>
        </div>
        <div style={{ background: headerColor4 }} id="line15">
          <span style={{ background: headerColor4 }} className="circle5"></span>
        </div>
        <div
          // id="no3"
          className="max-w-xl px-4 mx-24 mt-24 border h-80 rounded-2xl"
          style={{ borderColor: headerColor4 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/gift.svg" alt="gift.svg" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">{getLocaleString('gift_gold', locale)}</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            {getLocaleString('gift_gold_home_description', locale)}
          </p>
        </div>
      </div>

      {/* <div className="hidden lg:inline">
        <div className="flex items-center justify-center w-full ">
          <div className="">
            <div className="ml-16">
              <div
                className="h-40 ml-40 border w-72 rounded-2xl "
                style={{ borderColor: headerColor3 }}
              >
                <p className="my-4 mt-4 text-xl font-semibold text-center">Store Gold</p>
                <p className="text-center text-base font-normal text-[#010103]/50">
                  Store the gold you bought in <br /> our secured and insured <br /> partner vaults
                </p>
              </div>
            </div>
            <div style={{ background: headerColor4 }} id="line8"></div>
            <div style={{ background: headerColor4 }} id="line9"></div>
            <div style={{ background: headerColor4 }} id="line10">
              <span style={{ background: headerColor4 }} className="circle4"></span>
            </div>
            <div style={{ background: headerColor4 }} id="line11">
              <span style={{ background: headerColor4 }} className="circle4"></span>
            </div>
            <div style={{ background: headerColor4 }} id="line12">
              <span style={{ background: headerColor4 }} className="circle4"></span>
            </div>
            <div
              id="no1"
              className="mt-5 border h-80 w-72 rounded-2xl"
              style={{ borderColor: headerColor4 }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
              </div>
              <p className="mb-4 text-xl font-semibold text-center ">Sell Gold</p>
              <p className="text-center text-base font-normal text-[#010103]/50">
                Sell Gold bought from Gold <br /> Kinen app, in gold quantity <br /> (gms or bhori)
                or in monetary <br /> amounts as you desire.
              </p>
            </div>
            <div
              id="no2"
              className="mt-5 ml-3 border h-80 w-72 rounded-2xl"
              style={{ borderColor: headerColor4 }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/collect.png" alt="collect.png" className="" />
              </div>
              <p className="my-4 mt-4 text-xl font-semibold text-center">Collect Gold</p>
              <p className="text-center text-base font-normal text-[#010103]/50">
                Collect Gold bought from <br /> Gold Kinen app in form or <br /> Gold Coin or Gold
                Bars
              </p>
            </div>
            <div
              id="no3"
              className="mt-5 ml-3 border h-80 w-72 rounded-2xl"
              style={{ borderColor: headerColor4 }}
            >
              <div className="flex items-center justify-center mt-8">
                <ImageContainer src="/image/howItWorks/gift.svg" alt="gift.svg" className="" />
              </div>
              <p className="my-4 mt-4 text-xl font-semibold text-center">GIft Gold</p>
              <p className="text-center text-base font-normal text-[#010103]/50">
                Gift gold to your loved ones <br /> with a valid Gold Kinen <br /> account.
              </p>
            </div>
            <div style={{ background: headerColor5 }} id="line13">
              <span style={{ background: headerColor5 }} className="circle4"></span>
            </div>
            <div style={{ background: headerColor5 }} id="line14">
              <span style={{ background: headerColor5 }} className="circle4"></span>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="hidden mb-10 lg:inline">
        <div
          id="no4"
          className="border h-80 w-72 rounded-2xl"
          style={{ borderColor: headerColor5 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/car.svg" alt="car.svg" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">Home Delivery</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            Get your stored gold, from <br /> your vault, delivered right at <br /> your doorstep in
            forms of <br /> gold coins or gold bars
          </p>
        </div>
        <div
          id="no5"
          className="border h-80 w-72 rounded-2xl"
          style={{ borderColor: headerColor5 }}
        >
          <div className="flex items-center justify-center mt-8">
            <ImageContainer src="/image/howItWorks/withdraw.png" alt="withdraw.png" className="" />
          </div>
          <p className="my-4 mt-4 text-xl font-semibold text-center">Withdraw Cash</p>
          <p className="text-center text-base font-normal text-[#010103]/50">
            Withdraw your gold Savings <br /> as cash in your preferred <br /> MFS or Bank Account
          </p>
        </div>
      </div> */}

      <SliderPage />
    </div>
  );
};

export default HowItWorks;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize1() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize2() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize3() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function getWindowSize4() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize5() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize6() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function getWindowSize7() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize8() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
function getWindowSize9() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function getWindowSize10() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
// function getWindowSize11() {
//   const { innerWidth, innerHeight } = window;
//   return { innerWidth, innerHeight };
// }
