import { useState } from "react";
import { animated, useSpring } from "react-spring";
import Styles from "./Card.module.css";
interface props {
  title: string;
  description: string;
}

function Card({ title, description }: props) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });
  return (
    <animated.div
    className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      {/* <img src={image} alt="" /> */}
      <div className="py-16 w-50 h-80 md:w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
                 <p className="text-center text-3xl font-semibold text-[#010103]/80 ">{title}</p>
                 <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                 {description}
                 </p>
               </div>
    </animated.div>
  );
}

export default Card;
