import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import ImageContainer from '../components/ui/ImageContainer';
import Navbar from '../components/ui/Navbar';
import Footer from '../pages/landing/Footer';

function Layout() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center">
           <ImageContainer
                  src="/image/navbar/Logo.png"
                  alt="gklogo"
                  className="h-auto w-auto md:max-w-md xs:object-fill bg-white items-center mt-80"
                />
        </div>
      }
    >
      <div className="w-full">
        <div className="sticky top-0 z-50">
          <Navbar />
        </div>
        <div className=''>
          <Outlet />
        </div>
       <Footer/>
      </div>
    </Suspense>
  );
}

export default Layout;
