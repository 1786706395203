import React from 'react';

const InputField = React.forwardRef<
  HTMLInputElement,
  {
    type?: string | undefined;
    className?: string | undefined;
    errorMessage?: string | undefined;
    label?: string | undefined;
   
    readOnly?: boolean| undefined;
    placeholder?: string | undefined;
  }
>(({ type = 'text', className, errorMessage, label, placeholder, readOnly, ...otherProps }, ref) => (
  <div className={`w-full space-y-1 ${className}`}>
    {label && <h2 className={`text-primary font-semibold text-base ${errorMessage ? 'text-red-500' : ''}`}>{label}</h2>}
    <input
      {...otherProps}
      ref={ref}
      type={type}
      placeholder={placeholder}
     
      readOnly={readOnly}
      className={`w-full bg-white bg-opacity-90 rounded-lg  focus:outline-none focus:ring-2 focus:border-transparent text-primary p-3 ${className} ${
        errorMessage ? 'border-red-500 focus:ring-red-500' : 'focus:ring-secondary'
      }`}
    />
    {errorMessage && <p className="text-sm font-semibold text-red-500">{errorMessage}</p>}
  </div>
));

export default InputField;
