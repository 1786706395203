import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import InputField from '../../components/ui/InputField';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
// import { Toaster } from '../../components/ui/Toaster';
import { useRef, useState } from 'react';
interface IContactData {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber?: number | undefined;
  email: string;
  reasonForContact: string;
  message: string;
  concent: Boolean;
}

const validationSchema = Yup.object().shape({
  // name: Yup.string().trim().required('Required'),
  // companyName: Yup.string().trim().required('Required'),
  // phoneNumber: Yup.string().trim().required('Required'),
  // email: Yup.string().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  // reasonForContact: Yup.string().trim().required('Required'),
  // message: Yup.string().trim().required('Required'),
});
const ContactUs = () => {
  const locale = getData(LANGUAGE);
  const [success, setSuccess] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    captcha : ''
  });
  const captchaRef = useRef<any>(null);
  const {
    handleSubmit,
    register,
    reset,
    // getValues,
    formState: { errors },
  } = useForm<IContactData>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      // phoneNumber: ,
      email: '',
      reasonForContact: '',
      message: '',
      concent:false,
    },
  });
  const google_captcha_site_key: string = (process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY as string);

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessages(prevState => ({...prevState, captcha: '' }))
    let token = null
    if(captchaRef){
      token =  captchaRef?.current?.getValue();
    }
    if(token){
        let submit=await axios.post('https://gzdjpix518.execute-api.ap-southeast-1.amazonaws.com/prod/contact', data)
        reset();
        
        if (submit) {
          setSuccess('Email Sent Successfully');
          setTimeout(() => {
            setSuccess('');
            
          }, 5000);
        }
    }else{
      setErrorMessages(prevState => ({...prevState, captcha: "You must confirm you are not a robot" }))
    }
    
    
    // try {
    //   await JanttApi.post('/contact-us', data);
    //   onClose && onClose();
    //   reset();
    //   JanttToaster('Emails sent Successfully.', 'success');
    //   history.push('/');
    // } catch (error) {
    //   console.log('error', error);
    // }
  });
// const accept= getValues('concent');
// console.log(accept)
  return (
    <div id='contact-us' className="-mt-0 lg:px-28 md:px-24 px-9 bg-gradient-to-r from-[#e9be5a] to-[#c6952c] focus:outline-none">
      <p className="pt-20 text-4xl md:text-6xl font-semibold text-primary pb-2.5">
        {getLocaleString(`home_contact_us`, locale)}
      </p>
      <p className="pb-8 text-base font-normal text-primary">
        {getLocaleString(`home_contact_us_description`, locale)}
      </p>
      <form onSubmit={onSubmit}>
        <div>
          <div className="w-full space-y-4 lg:space-x-8 lg:flex lg:space-y-0 text-primary">
            <div className="w-full space-y-4 lg:w-1/2">
              <div className='flex space-x-8'>
                <div className='w-full'>
                  <InputField
                    label={getLocaleString(`home_contact_your_first_name`, locale)}
                    {...register('firstName')}
                    errorMessage={errors.firstName ? errors.firstName.message : undefined}
                    className='w-full text-primary'
                  />
                </div>
                    <div className='w-full'>
                  <InputField
                    label={getLocaleString(`home_contact_your_last_name`, locale)}
                    {...register('lastName')}
                    errorMessage={errors.lastName ? errors.lastName.message : undefined}
                    className='w-full '
                  />
                </div>
              </div>
              <InputField
                label={getLocaleString(`home_contact_your_website_or_company`, locale)}
                {...register('companyName')}
                errorMessage={errors.companyName ? errors.companyName.message : undefined}
              />
              <InputField
                label={getLocaleString(`home_contact_phone_number`, locale)}
                type='number'
                
                {...register('phoneNumber')}
                errorMessage={errors.phoneNumber ? errors.phoneNumber.message : undefined}
              />
              <InputField
                label={getLocaleString(`home_contact_email_address`, locale)}
                type='email'
                {...register('email')}
                errorMessage={errors.email ? errors.email.message : undefined}
              />
            </div>
            <div className="w-full space-y-4 lg:w-1/2">
              <InputField
                label={getLocaleString(`home_contact_reason_for_contact`, locale)}
                {...register('reasonForContact')}
                errorMessage={errors.reasonForContact ? errors.reasonForContact.message : undefined}
              />
              <div className="w-full">
                <h2
                  className={`text-base font-semibold text-primary ${
                    errors.reasonForContact ? 'text-red-500' : ''
                  }`}
                >
                 {getLocaleString(`home_contact_message`, locale)}
                </h2>

                <textarea
                  placeholder={getLocaleString(`home_contact_message`, locale)}
                  {...register('message')}
                  rows={7}
                  className={`w-full rounded-lg bg-white bg-opacity-90  p-3 text-primary  focus:border-transparent focus:outline-none focus:ring-2  ${
                    errors.reasonForContact
                      ? 'border-red-500 focus:ring-red-500'
                      : 'focus:ring-secondary'
                  }`}
                />
                {errors.message && (
                  <p className="text-sm font-semibold text-red-500">{errors.message.message}</p>
                )}
              </div>
             <div className='flex space-x-3'>
                <label className='flex space-x-2'>
                  <input type="checkbox" {...register('concent')} className="w-4 bg-[#525E74]/40" style={{color:'#CEC382'}} />{' '}
                  <p className="text-primary">
                    {getLocaleString(`home_contact_check_message`, locale)}
                  </p>
                </label>
                    <div className="font-semibold underline text-primary"><Link to={`/${locale}/privacy_policy`} className="block">
                    {getLocaleString(`learn_more`, locale)}
                  </Link></div>{' '}
             </div>
{success.length ? (
        <div className="text-sm font-bold text-primary">{success}</div>
      ) : (
        ''
      )}
              
              <div className='formGroup'>
                <ReCAPTCHA sitekey={google_captcha_site_key} ref={captchaRef}  />
              </div>
              {errorMessages?.captcha && (
                  <p style={{color: 'red'}}>{errorMessages?.captcha}</p>
                )}
              
              <div className="pt-10 pb-28">
                <button className="py-4 text-base font-semibold text-[#e9be5a] border rounded-lg px-14 bg-primary focus:outline-none border-primary hover:bg-primary hover:text-white" >
                  {getLocaleString(`submit`, locale)}
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
