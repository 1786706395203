import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { LANGUAGE } from '../constants/localStorage';
import { setData } from '../services/storage';

const useLanguageSwitch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageToggle = (languageStr: string) => {
    languageStr && setData(LANGUAGE, languageStr);
    
    let pathName = location.pathname.replace(/^\/|\/$/g, '');

    if(location.hash.includes('about')){
      pathName = 'about-us'
    }
  
    if(pathName === 'eula'){
      pathName = 'terms-of-use'
    }

    if(pathName.split('/').length == 1 && pathName.split('/')[0] !== 'bn' && pathName.split('/')[0] !== 'en' ){
      pathName = `${languageStr}/${pathName}`
    }

    // pathName = pathName.includes('en') ? pathName : `en/${pathName}`
    const componentPath = pathName.split('/').length > 1 ? pathName.split('/')[1] : ''
    navigate(`/${languageStr}/${componentPath}`,{state:{locale:languageStr}});
  }
  return { handleLanguageToggle };
};

export default useLanguageSwitch;