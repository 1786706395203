import ImageContainer from '../../components/ui/ImageContainer';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';
import GoldChart from './GoldChart';

const GoldKinen = () => {
  const locale = getData(LANGUAGE);
  return (
    <div>
      <div className="">
        <div className="flex items-center justify-center py-9 md:py-12 lg:py-16 ">
          <div className=''>
            <p className="text-[#010103]/54 my-4 text-center  text-base font-normal">
              {getLocaleString('why_gold_kinen', locale)}
            </p>
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">{getLocaleString('gold_new_feature', locale)}</p>
          </div>
        </div>
      </div>
      <div className="bg-[#D9D9D9]/20  px-9 md:px-20 lg:px-28">
        <div className="py-10 md:py-20 lg:py-8 lg:flex lg:px-36">
          <div className="w-full lg:flex lg:w-1/2 lg:items-center lg:justify-center">
            <div className="">
              <ImageContainer src={'/image/goldKinen/goldKinen1.jpg'} alt="service1" className="" />
            </div>
          </div>
          <div className="flex items-start w-full pt-8 lg:w-1/2 lg:pl-16">
            <div>
              <p className="py-4 text-2xl font-semibold text-[#010103]/80">{getLocaleString('why_gold', locale)}</p>
              <p className="text-[#010103]/54 text-base font-normal text-justify">
                {getLocaleString('why_gold_description', locale)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-16 space-y-8 bg-white md:pt-20 px-9 md:px-20 lg:space-y-0 lg:px-28">
        <div className="w-full lg:flex lg:items-center lg:justify-center">
          <div className="">
            <p className="text-start text-3xl font-semibold text-[#010103]/80 md:text-center md:text-5xl">
              {getLocaleString('gold_over_time', locale)}
            </p>
            <p className="text-[#010103]/54 pt-6 text-xl font-normal text-center lg:max-w-4xl">
              {getLocaleString('gold_over_time_description', locale)}
            </p>
          </div>
        </div>
        <div className="w-full pt-10 lg:flex lg:items-center lg:justify-center">
          {/* <GoldChart/> */}
          <GoldChart></GoldChart>
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-24 lg:mt-28 px-9 md:px-20 lg:px-28 mb-28">
        <div className="flex flex-col-reverse lg:flex-row lg:space-x-14">
          <div className="w-full lg:flex lg:w-1/2 lg:items-center lg:justify-center">
            {/* <ImageContainer
              src="/image/goldKinen/Bar-Front-Side.png"
              alt="goldKinen2"
              className="w-1/2 h-1/2 sm:w-auto sm:h-96"
            /> */}
            <ImageContainer
              src="/image/goldKinen/Bar-Coin.png"
              alt="goldKinen2"
              className=""
            />
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-[#010103]/54 pb-2 text-base font-normal uppercase">
              {getLocaleString('why_gold_from_gold_kinen', locale)}
            </p>
            <p className="text-2xl font-semibold capitalize text-[#010103]/80 md:text-5xl">
              {getLocaleString('why_gold_from_gold_kinen_p1', locale)}
            </p>
            <div className="pt-8 pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">{getLocaleString('why_gold_from_gold_kinen_p2', locale)}</p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                {getLocaleString('why_gold_from_gold_kinen_p2_description', locale)}

              </p>
            </div>
            {/* <div className="pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">Your gold is safeguarded</p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                Any gold you buy with us is stored in the trusted vault of our locker 
                partners. Your gold is fully insured against all risks at full replacement
                value.
              </p>
            </div> */}
            <div className="pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">
                {getLocaleString('why_gold_from_gold_kinen_p3', locale)}
              </p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                {getLocaleString('why_gold_from_gold_kinen_p3_description', locale)}

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldKinen;
