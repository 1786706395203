import ImageContainer from '../../components/ui/ImageContainer';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';
import { getLocaleString } from '../../utils/locales';

function AboutUs() {
  const locale = getData(LANGUAGE);
  return (
    <div id="about-us" className="w-full mt-16 lg:flex bg-primary">
      <div className="w-full py-10 bg-primary text-white focus:outline-none px-9 md:px-24 lg:w-[60%] lg:px-32 max-h-fit  overflow-y-auto">
        <div className="py-12">
          <p className="mb-8 text-3xl font-medium text-white/80">{getLocaleString('about_us_home', locale)}</p>
          <p className="text-4xl font-black capitalize text-[#e9be5a] md:text-6xl">
            {/* An App That Will better secure your future. */}
            {getLocaleString('about_us_title', locale)}
          </p>
          <p className="text-justify text-white/54 py-8 pb-10 text-lg font-light">
            {/* Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
            consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. */}
            {getLocaleString('about_us_description', locale)}<br/>
{/* With the Gold Kinen App, you can Buy, Sell and Collect Gold  within a few seconds using your mobile phone. Navigate the app in English or Bangla, with an easy to find toggle switch. Gift gold to your loved ones instantly. Keep your gold savings within your reach in a few taps. <br/>
Gold bought within the app is stored safely in secured and fully insured vaults. You can sell your bought gold anytime and from anywhere via the Gold Kinen app and receive cash in your desired mobile wallet or bank account. We offer multiple payment options via Banks and other Mobile Financial Services to ensure seamless and protected transactions throughout the app. <br/>

Furthermore, Gold Kinen assures customers of delivering the highest quality of gold bars and coins; each of which are tested and certified by leading gold certification agencies of the country. In addition, we strive and promise the highest level of integrity at each stage of our operations, making the Gold Kinen experience a safe and trusted one for our valued users.  */}
          </p>
          {/* <div className="flex justify-center pt-8 ">
          <ImageContainer src="/image/aboutUs/About Us-Seal.png" alt="" className="h-16 lg:h-32" />
          
        </div> */}

          
        </div>
      </div>
      <div className="w-full lg:w-[40%]">
        
          <ImageContainer src="/image/aboutUs/AboutUs.jpg" alt="" className='' />
        
      </div>
      {/* <div className="hidden w-full lg:inline lg:w-1/4">
        <div className="flex items-center justify-center bg-[#F0EDDA] py-10">
          <ImageContainer src="/image/aboutUs/phone.png" alt="" className="" />
        </div>
        <div className="bg-[#F0EDDA]/80 py-10">
          <div className="flex items-center justify-center pb-10">
            <ImageContainer src="/image/aboutUs/products.png" alt="" className="" />
          </div>
          <div className="flex items-center justify-center">
            <div>
              <p className="pb-2 text-2xl font-semibold text-white/80">Our Products</p>
              <p className="text-white/54 text-base font-normal">
                Amet minim mollit non deserunt <br /> ullamco est sit aliqua dolor do <br /> amet
                sint. Velit officia
              </p>
            </div>
          </div>
        </div>
      </div> */}
      
    </div>
  );
}

export default AboutUs;
