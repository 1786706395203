import { Button, ButtonGroup } from "@material-ui/core";
import { ApexOptions } from "apexcharts";
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getLocaleString } from '../../utils/locales';
import { LANGUAGE } from '../../constants/localStorage';
import { getData } from '../../services/storage';


function GoldChart () {

const [duration, setDuration] = useState('1');
const locale = getData(LANGUAGE);
  const series= [
      {
        name: 'Gold Price',
        data: duration==='1'? [8010,7810,8370,8440,8290,8440,8640,8680,8570,8820,9420,9520]: duration==='2'?[58028,76341,73133,91912,109875]:[48405,42223,45898,47939,52254,58028,76341,73133,91912,109875],
      },
    ];
  const options: ApexOptions=  {
        chart: {
          width: '100%',
          height: 350,
          type: 'line',
          toolbar:{
            show:false
          }
        },
        stroke: {
          width: [0, 4],
          curve: 'smooth',
        },
        title: {
          text: '',
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '৳ '+val;
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        colors:['#e9be5a'],
        labels: duration==='1'?['Jan','Feb','Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov','Dec' ]:duration==='2'?['2019','2020','2021','2022','2023']:['2014','2015','2016','2017','2018','2019','2020','2021','2022','2023'],
      }


  
    return (
      <div className='lg:w-1/2 space-y-5'>
        <p className="flex justify-center text-lg font-semibold">{getLocaleString('chartHeader', locale)}</p>
        <div className="flex justify-center">
          <ButtonGroup variant="contained" aria-label="outlined primary button group" >
            <Button style={{backgroundColor:duration==='1'?'#e9be5a':'#ffffffff'}} onClick={()=>{setDuration('1');}}>{getLocaleString('yearly', locale)}</Button>
            <Button style={{backgroundColor:duration==='2'?'#e9be5a':'#ffffffff'}} onClick={()=>setDuration('2')}>{getLocaleString('Fiveyear', locale)}</Button>
            <Button style={{backgroundColor:duration==='3'?'#e9be5a':'#ffffffff'}} onClick={()=>setDuration('3')}>{getLocaleString('Tenyear', locale)}</Button>
          </ButtonGroup>
        </div>
        <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height="350"
        />
      </div>
    );
  
}

export default GoldChart