// import { useState } from 'react';
// import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import { IVideo } from '../../types';
// import { videos } from '../../utils';
import './slick.css';

const SliderPage = () => {
  // const [, setIsPrevShow] = useState(false);
  // const settings: Settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   autoplay: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  //   dotsClass: 'button__bar',
  //   beforeChange: (next: any) => {
  //     if (next > 0) {
  //       setIsPrevShow(true);
  //     } else {
  //       setIsPrevShow(false);
  //     }
  //   },
  // };

  return (
    <div className='mt-28'>
      {/* <Slider {...settings} className="z-30 w-full">
        {videos.map((item: IVideo, key: number): any => {
          return (
            <div key={key} className="lg:px-28 px-9 md:px-24">
              {' '}
              <video
                id="vid"
                playsInline
                autoPlay
                muted
                loop
                className="flex items-center justify-center w-full border rounded-3xl"
                // style={{width: '1222px',height: '509px'}}
                controls
              >
                <source src={''} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        })}
      </Slider> */}
    </div>
  );
};
export default SliderPage;
